<template>
  <div class="project">
    <div class="titleBar">
      <PlateTitle
        title="订单列表"
        class="plate"
      />
    </div>
    <div class="search">
      <!-- <img
        src="@/assets/image/icon-ss.png"
        alt=""
      >
      <a-input
        v-model="query.keywords"
        placeholder="输入关键词搜索"
      /> -->
      <!-- <div class="phase">
        <span>项目名 </span>
        <a-input />
      </div> -->
      <a-row>
        <a-col :span="8">
          <span>保函编号</span>
          <a-input
            v-model="query.thirdOrderCode"
            placeholder="输入保函编号搜索"
          />
        </a-col>
        <a-col :span="8">
          <span>标段名称</span>
          <a-input
            v-model="query.sectionName"
            placeholder="输入标段名称搜索"
          />
        </a-col>
        <a-col :span="8">
          <span>公告日期</span>
          <a-range-picker
            v-model="query.startTime"
            :locale="locale"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
          />
        </a-col>
        <a-col :span="8">
          <span>标段编号</span>
          <a-input
            v-model="query.sectionCode"
            placeholder="输入标段编号搜索"
          />
        </a-col>
        <a-col :span="8">
          <span>状态</span>
          <a-select v-model="query.status">
            <a-select-option
              v-for="item in statusList"
              :key="item.label"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :span="8">
          <a-button
            type="primary"
            @click="toQuery"
          >
            查询
          </a-button>
          <a-button
            type="primary"
            @click="reset"
          >
            重置
          </a-button>
        </a-col>
      </a-row>
    </div>
    <!-- 表格 -->
    <div class="table">
      <a-list
        item-layout="vertical"
        size="large"
        :pagination="isPagination ? pagination : null"
        :data-source="orderList"
        :loading="loading"
      >
        <a-list-item
          slot="renderItem"
          key="item.title"
          slot-scope="item"
        >
          <div class="forTable">
            <!-- <div class="top">
              <span class="top1">{{ item.bidsectionName }}</span>
              <p class="top3">
                {{ item.bidsectionCode }}
              </p>
            </div> -->
            <div class="bottom">
              <!-- <div class="div1">
                <p>保函类型</p>
                <p>{{ item.type | typeFormat }}</p>
              </div> -->
              <div class="div1">
                <p>保函编号</p>
                <p>{{ item.thirdOrderCode }}</p>
              </div>
              <div class="div2">
                <p>项目名称</p>
                <p>{{ item.projectName }}</p>
              </div>
              <div class="div2">
                <p>标段名称</p>
                <p>{{ item.sectionName }}</p>
              </div>
              <div class="div3">
                <p>项目标段编号</p>
                <p>{{ item.sectionCode }}</p>
              </div>
              <div class="div5">
                <p>担保保额(元)</p>
                <p>￥{{ item.sumAmount }}</p>
              </div>
              <div class="div6">
                <p>担保保费(元)</p>
                <p>￥{{ item.sumPremium }}</p>
              </div>
              <div class="div7">
                <p>担保状态</p>
                <p>{{ insureStatus(item.status) }}</p>
              </div>
              <div style="width: 70px;">
                <p>公告日期</p>
                <p>{{ dateFormat(item.bulletinReleaseTime) }}</p>
              </div>
              <div>
                <a-button
                  type="primary"
                  v-if="true"
                  @click="look(item)"
                >
                  查看
                </a-button>
              </div>
              <!-- <div>
                <a-button
                  type="primary"
                  v-if="item.isInsureInvoice"
                  @click="look(item)"
                >
                  开票
                </a-button>
              </div> -->
              <!-- <a-button
                type="primary"
                class="applyed"
                v-if="item.status === '-11'"
                @click="handelEdit(item)"
              >
                修改
              </a-button> -->
              <!-- <div>
                <a-button
                  type="primary"
                  v-if="item.curNode === '1' || item.curNode === '2' || item.curNode === '3'"
                  @click="cancel(item)"
                >
                  取消订单
                </a-button>
                <a-button
                  type="primary"
                  v-if="item.status === '2' || item.status === '16' || item.status === '12'"
                  class="applyed"
                >
                  已取消
                </a-button>
              </div> -->
              <!-- <div>
                <a-button
                  type="primary"
                  v-if="true"
                  @click="apply(item)"
                >
                  申请
                </a-button>
                <a-button
                  type="primary"
                  v-else
                  class="applyed"
                >
                  已申请
                </a-button>
              </div> -->
            </div>
          </div>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
import PlateTitle from '@/components/PlateTitle';
import { dateFormat } from '@/utils/format.js';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { orderInfos, orderInfoGive } from '@/api/orderCenter/order.js';
import { dictDetail } from '@/api/performance.js';
export default {
  name: 'ProView',
  components: {
    PlateTitle,
  },
  filters: {
    typeFormat(val) {
      let valStr = '';
      switch (val) {
        case '0':
          valStr = '投标保函';
          break;
        case '2':
          valStr = '履约保函';
          break;
        case '3':
          valStr = '农民工保函';
          break;
        default:
          valStr = '';
      }
      return valStr;
    },
  },
  data() {
    return {
      typeList: [],
      statusList: [],
      dateFormat,
      locale,
      title: {},
      query: {
        type: null,
        status: null,
      },
      orderList: [],
      loading: false,
      isPagination: false,
      pagination: {
        onChange: page => {
          this.fetch(page);
        },
        current: 1,
        pageSize: 5,
        total: 0,
      },
      actions: [
        { type: 'star-o', text: '156' },
        { type: 'like-o', text: '156' },
        { type: 'message', text: '2' },
      ],
      curNodes: [{
        label: '未提交',
        value: '1',
      }, {
        label: '已提交',
        value: '2',
      }, {
        label: '待支付',
        value: '3',
      }, {
        label: '已支付',
        value: '4',
      }, {
        label: '已取消',
        value: '6',
      }, {
        label: '已完成',
        value: '7',
      }, {
        label: '退保审核',
        value: '8',
      }, {
        label: '退保成功',
        value: '9',
      }, {
        label: '中标',
        value: '10',
      }, {
        label: '退保失败',
        value: '-4',
      }, {
        label: '投保审核失败',
        value: '-3',
      }, {
        label: '已关闭',
        value: '-2',
      }, {
        label: '支付失败',
        value: '-1',
      }],
    };
  },
  mounted() {
    this.getDictDetail();
    this.info({ size: 5, page: 1 });
  },
  methods: {
    getDictDetail() {
      dictDetail({ dictName: 'insureType' }).then(res => {
        this.typeList = res.data.data;
      });
      dictDetail({ dictName: 'insureStatus' }).then(res => {
        this.statusList = res.data.data;
      });
    },
    insureStatus(status) {
      if (this.statusList) {
        for (const i in this.statusList) {
          if (this.statusList[i].value === status) {
            return this.statusList[i].label;
          }
        }
      } else {
        return '';
      }
    },
    info(params = {}) {
      orderInfos({ ...params }).then(res => {
        if (res.data.status === 'FAIL') {
          // this.$message.warning(res.data.errorMsg);
          // if (res.data.errorMsg === '用户未登录') {
          //   this.$router.push({ name: 'login' });
          // }
        } else {
          res.data.data.totalElements === 0 ? this.isPagination = false : this.isPagination = true;
          this.orderList = res.data.data.content;
          this.pagination.total = res.data.data.totalElements;
        }
      });
    },
    fetch(page) {
      this.pagination.current = page;
      console.log(this.query.startTime);
      const params = {
        size: 5,
        page,
        thirdOrderCode: this.query.thirdOrderCode,
        sectionName: this.query.sectionName,
        sectionCode: this.query.sectionCode,
        status: this.query.status,
        startTime: this.query.startTime ? this.query.startTime[0] : null,
        endTime: this.query.startTime ? this.query.startTime[1] : null,
      };
      this.loading = true;
      orderInfos({ ...params }).then(res => {
        this.loading = false;
        // this.orderList = res.data.data;
        this.orderList = res.data.data.content;
        this.pagination.total = res.data.data.totalElements;
      });
    },
    // 申请
    // apply(e) {

    // },
    // 查询
    toQuery() {
      this.fetch(1);
      this.pagination.current = 1;
    },
    reset() {
      this.query.thirdOrderCode = null;
      this.query.sectionName = null;
      this.query.sectionCode = null;
      this.query.status = null;
      this.toQuery();
    },
    look(e) {
      if (e.status === '-1') {
        this.$router.push({ path: '/performance', query: { orderNo: e.thirdOrderCode, type: e.type } });
      } else {
        window.localStorage.setItem('type', e.type);
        this.$router.push({ path: '/insure', query: { orderNo: e.thirdOrderCode } });
      }
    },
    cancel(e) {
      orderInfoGive({ orderNo: e.orderNo }).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.$message.success('取消订单成功!');
          this.info({ size: 5, page: this.pagination.current });
        } else {
          this.$message.warning(res.data.errorMsg);
        }
      });
    },
    handelEdit(e) {
      this.$router.push({ path: '/performance', query: { orderNo: e.thirdOrderCode, type: e.type } });
    },
  },
};
</script>
<style lang="less" scoped>
.project {
  min-height: 666px;
}
.search {
  // display: flex;
  position: relative;
  margin-bottom: 30px;
  img {
    z-index: 99;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 39px;
    left: 10px;
  }
  /deep/ .ant-input-affix-wrapper {
    width: 340px;
  }
  /deep/ .ant-input {
    // width: 300px;
    width: 230px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #e1e8ef;
    border-radius: 3px;
    // margin: 30px 0 20px 0;
    margin: 0 0 20px 0;
    // padding-left: 40px;
  }
  .phase {
    margin: 30px 20px 0;

  }
  span {
    margin-right: 5px;
  }
  /deep/ .ant-select-selection {
    // margin-left: 5px;
    // width: 120px;
    width: 170px;
  }
  button {
    // margin-top: 30px;
    margin-right: 10px;
  }
}
.table {
  /deep/ .ant-list-empty-text {
    min-height: 500px;
    .ant-empty-normal {
      margin-top: 220px;
    }
  }
  /deep/ .ant-list-item {
    border-bottom: none;
    padding: 0 0 20px 0;
  }
  /deep/ .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border: none;
  }
  /deep/ .ant-list-pagination {
    margin: 0 0 8px 0;
  }
  .top {
    width: 920px;
    height: 80px;
    background: #f7faff;
    padding: 21px 41px 17px 32px;
    position: relative;
    border: 1px solid #ebf2f7;
    border-bottom: none;
    font-family: Microsoft YaHei;
    .top1 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .top2 {
      font-size: 14px;
      font-weight: 400;
      color: #aaaaaa;
      position: absolute;
      right: 41px;
    }
    .top3 {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin-top: 5px;
    }
  }
  .bottom {
    display: flex;
    width: 920px;
    background: #ffffff;
    border: 1px solid #ebf2f7;
    padding: 25px 40px 20px 31px;
    p {
      margin: 0;
    }
    .div1 {
      // width: 100px;
      width: 140px;
    }
    .div2 {
      width: 100px;
    }
    .div3 {
      width: 140px;
    }
    .div4 {
      width: 56px;
    }
    .div5 {
      width: 100px;
    }
    .div6 {
      width: 100px;
    }
    .div7 {
      width: 70px;
    }
    div {
      margin: 0 6px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        word-break: break-word; /* 文本行的任意字内断开 */
        word-wrap: break-word; /* IE */
        &:nth-child(1) {
          color: #aaaaaa;
        }
        &:nth-child(2) {
          color: #333333;
        }
      }
      a {
        margin-right: 10px;
      }
      .applyed {
        width: 88px;
        background: #b2d7f6;
        border: #b2d7f6;
      }
    }
  }
}
</style>
