import request from '@/utils/request';

export function orderInfos(params) {
  return request({
    url: '/api/apply/orderInfos',
    method: 'get',
    headers: {
      'Cache-control': 'no-cache',
      Pragma: 'no-cache',
    },
    params,
  });
}

export function cetRandom(params) {
  return request({
    url: '/api/accCertification/cetRandom',
    method: 'get',
    params,
  });
}
export function certVerify(params) {
  return request({
    url: '/api/accCertification/certVerify',
    method: 'post',
    params,
  });
}
export function orderInfoGive(params) {
  return request({
    url: '/orderInfoApi/orderInfoGive',
    method: 'post',
    params,
  });
}

export default { orderInfos, cetRandom, certVerify, orderInfoGive };
